<template>
  <div :class="{ 'has-errors': hasErrors }">
    <label
      class="typeset-6"
      :class="[
        block ? 'flex p-4' : 'inline-flex label',
        labelClass,
        isChecked ? checkedClass : uncheckedClass
      ]">
      <input
        :id="`${inputId}-${value}`"
        :class="inputClass"
        :name="inputId"
        :checked="value === currentValue"
        type="radio"
        @change="$emit('input', value)" />
      <div>
        <div>
          <span v-html="label"></span>
          <span v-if="required">*</span>
        </div>
        <slot name="description"></slot>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "RadioInput",
  model: {
    prop: "currentValue",
    event: "input"
  },
  props: {
    value: {
      required: true
    },
    currentValue: {
      required: false
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      required: true,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    },
    block: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: "gap-x-3"
    },
    checkedClass: {
      type: String,
      default: ""
    },
    uncheckedClass: {
      type: String,
      default: ""
    }
  },
  emits: ["input"],
  computed: {
    isChecked() {
      return this.value === this.currentValue;
    },
    inputErrors() {
      return this.errors || [];
    },
    hasErrors() {
      return this.inputErrors.length > 0;
    }
  }
};
</script>
